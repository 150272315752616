import React from 'react';
import { Helmet } from 'react-helmet';

import Navigation from 'components/Navigation';
import Footer from 'components/Footer';
import Hero from 'components/pages/carparc/hero/carParcHero';
import WhatIs from 'components/pages/carparc/products/WhatIsCarParc';
import TheData from 'components/pages/carparc/products/theData';
import TableParc from 'components/pages/carparc/products/tableParc';

export default function CarParcPage() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Car Parc</title>
                <link rel="canonical" href="https://blueflag.com.au/car-parc/" />

                <meta name="description" content="An accurate and reliable count of your Units in Operation (UIO)." />
                <meta name="keywords" content="car parc, Units in Operation (UIO), units in operation, vehicles in operation" />
            </Helmet>
            <Navigation />
            <Hero />
            <WhatIs />
            <TheData />
            <TableParc />
            <Footer />
        </>
    );
}
