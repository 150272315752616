import '../carparc.scss';
import React from 'react';

import Container from 'components/layout/Container';
import Section from 'components/layout/Section';

import DataSource from '../../../../images/images/nevdis/datasources.png';

import Button from 'components/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

export default function TheData() {
    return (
        <>
            <Container theme="light" className="paddingY5" autoMinHeight={false}>
                <Section className="VehicleImage">
                    <img src={DataSource} alt="datasources" width={500} />
                </Section>
                <Section>
                    <div className="product">
                        <h2 className="productHeader">The Data</h2>
                        <p className="productCopy">
                            You supply the VINs of the vehicles they would like to identify the registration status and location of to Blue Flag. Blue Flag processes these VINs through a centralised
                            system which has a direct link to the each state’s road traffic authority in real-time to return:
                            <ul>
                                <li>Registration Status</li>
                                <li>Postcode</li>
                                <li>Suburb</li>
                            </ul>
                        </p>

                        <Button to="https://blueflag.com.au/contact-us/" type="new-outline" color="primary">
                            Learn More <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                    </div>
                </Section>
            </Container>
        </>
    );
}
