import '../carparc.scss';
import React from 'react';

import Container from 'components/layout/Container';
import Section from 'components/layout/Section';

import Button from 'components/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import AusMap from 'assets/images/aus_map.svg';

export default function WhatIsCarParc() {
    return (
        <>
            <Container theme="light" className="reverseMobile paddingY6" autoMinHeight={false}>
                <Section>
                    <div className="product">
                        <h2 className="productHeader">What is Car Parc?</h2>
                        <p className="whatIsCopy">
                            Car Parc is another term for vehicle units in operation (UIO or VIO) which refers to the total number of vehicles that are actively registered across Australia by an
                            individual or organisation. This can include any type of vehicle such as cars, trucks, buses, vans, and other specialised vehicles used for various purposes.
                        </p>

                        <Button to="https://blueflag.com.au/contact-us/" type="new-outline" color="primary">
                            Learn More <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                    </div>
                </Section>

                <Section className="forecastingImage">
                    <AusMap />
                </Section>
            </Container>
        </>
    );
}
